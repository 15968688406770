import React, { useEffect, useState } from "react";
import AdminSidebar from "./AdminSidebar.tsx";
import AdminHeader from "./AdminHeader.tsx";
import { getAuthFromToken } from "../../common/common.tsx";

const AdminLayout = ({ children }: { children: React.ReactNode }) => {
    // const adminList = [301, 21]

    // useEffect(() => {
    //     const userId = getCookieUserId();
    //     if (!userId) {
    //         alert("로그인 후 접속하시기 바랍니다.");
    //         window.location.href = "/user/login";
    //     } else if (!adminList.includes(userId)) {
    //         alert("관리자만 접근 가능합니다.");
    //         window.location.href = "/";
    //     }
    // }, []);

    return (
        <div className="admin-layout">
            <AdminSidebar />
            <div className="admin-main-content">
                {/*<AdminHeader />*/}
                <div className="admin-area">
                    {children}
                </div>
            </div>
        </div>
    );
}

export default AdminLayout;
