import React from "react";
import { useEffect, } from 'react';

import Layout from "../../layout/Layout.tsx";
import Slider from "../../layout/Slider.tsx";


function NoAccessPage() {

  useEffect(() => {
  }, []);

  return (
    <Layout>
      <div className="contents contest">
        <section>
          <div style={{ textAlign: "center", padding: "50px" }}>
            <br />
            <h1>관리자만 접근 가능한 페이지 입니다.</h1>
            <br />
          </div>
        </section>
        <Slider></Slider>
      </div>
    </Layout>
  );
}

export default NoAccessPage;
