import React, { useEffect } from "react";

function Footer() {
return (
    <footer>
        <img className="mo" src='/assets/images/common/mo/footer_logo.png' alt="woog"/>
        <img className="pc" src='/assets/images/common/logo_gray.png' alt="woog"/>
        <a href="#" className="btn_top">top</a>
        <div className="copyright pc" >
            주소 경기도 과천시 과천동 379-12번지 {/*| 통신판매업신고번호 2011-경기안산-03461*/} | 등록번호 373-16-02339 | 상호 우리그라운드 | 대표자 김소연<br/>
            고객센터 전화 문의 010-8138-4755 {/*FAX 02-6744-5880*/} | 이메일 wooriground@wooriground.com   | 콘텐츠산업진흥법에 의한 콘텐츠 보호 안내 <br/>
            Copyright(c) 2023. wooriground.com. All right reserved.
        </div>
        <div className="copyright mo">
            <b>당신이 원하는 대회를 원하는 장소에서</b> 당신의 대회를 알리고 싶다면,
            <p><b>WOORI GROUND!</b> WOOG ME! CONTACT ME!</p>
            <span><b>문의 010-8138-4755| 이메일 wooriground@wooriground.com</b></span>
            <span><b>등록번호 373-16-02339 | 상호 우리그라운드 | 대표자 김소연</b></span>
            <span>Copyright(c) 2023. wooriground.com. All right reserved.</span>
        </div>
    </footer>
    )
}

export default Footer;