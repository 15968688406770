import axiosInstance from './axiosInstance.tsx';
import axios  from 'axios';
import {API} from '../config/config.tsx';
import {Cookies} from 'react-cookie';
import moment from "moment";
import { jwtDecode } from 'jwt-decode';

export function getAuthFromToken() {
    const token = getAccessToken();
    // 토큰이 없는 경우 처리
    if (!token) {
        return null;
    }

    // jwt-decode 라이브러리로 토큰을 디코딩
    try {
        const decodedToken = jwtDecode(token); // 토큰의 payload를 디코딩

        // 디코딩된 데이터에서 auth 정보를 추출
        const auth = decodedToken.auth;

        return auth; // 권한 리스트 반환
    } catch (error) {
        console.error('Failed to decode token', error);
        return null;
    }
}

export type axiosParam ={
    method : string,
    url : string,
    params : object   
}

export const CONSTANTS  = {
    LOGIN_DIVS_CD_01 : 'LOGIN_DIVS_CD_01',
    LOGIN_DIVS_CD_02 : 'LOGIN_DIVS_CD_02',
    LOGIN_DIVS_CD_03 : 'LOGIN_DIVS_CD_03',
    REQ_RSLT_CD_01 : 'REQ_RSLT_CD_01',
    REQ_RSLT_CD_02 : 'REQ_RSLT_CD_02',
    REQ_RSLT_CD_03 : 'REQ_RSLT_CD_03',
    REQ_RSLT_CD_04 : 'REQ_RSLT_CD_04',
    REQ_RSLT_CD_05 : 'REQ_RSLT_CD_05',
    GAME_CLSF_KIND_CD_01 : 'GAME_CLSF_KIND_CD_01',
    GAME_CLSF_KIND_CD_02 : 'GAME_CLSF_KIND_CD_02',
    GAME_CLSF_KIND_CD_03 : 'GAME_CLSF_KIND_CD_03',
    GAME_CLSF_KIND_CD_04 : 'GAME_CLSF_KIND_CD_04',
    GAME_CLSF_KIND_CD_05 : 'GAME_CLSF_KIND_CD_05',
    GAME_CLSF_KIND_CD_06 : 'GAME_CLSF_KIND_CD_06',
    GAME_KIND_0075 : 'GAME_KIND_0075',
    ROLE_CMPT : 'ROLE_CMPT',
    ROLE_CLUB : 'ROLE_CLUB',
    ROLE_GYM : 'ROLE_GYM',
    ROLE_ADMIN : 'ROLE_ADMIN',
  };

// https://third9.github.io/posts/Axios%EB%8B%A4%EC%96%91%ED%95%98%EA%B2%8C_%ED%99%9C%EC%9A%A9%ED%95%98%EA%B8%B0-interceptor/ 
// 위 해당 블로그 참고
export function fn_axios_post_excel_down(axiosParam, successCallback, failCallback) {
    
    axios({
        method: 'POST',
        url: axiosParam.url,
        responseType: 'blob',
        headers: {
            "Content-Type": "application/json"
        },   
        data: axiosParam.params
    })
    .then(response =>{
        if(successCallback) {
            successCallback(response);
        } else {
            console.log(response);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', response.headers['content-disposition'].split('filename="')[1].split('.')[0]);
            document.body.appendChild(link);
            link.click();
        }
    })

}
export function fn_axios(axiosParam, successCallback, failCallback) {
    
    if(axiosParam.method === 'POST') {
        fn_post_axios(axiosParam, successCallback, failCallback);
    } else if(axiosParam.method === 'DELETE') {
        fn_delete_axios(axiosParam, successCallback, failCallback);
    } else {
        fn_get_axios(axiosParam, successCallback, failCallback);
    }

}
export function fn_post_axios(axiosParam, successCallback, failCallback) {
    axiosInstance.post(axiosParam.url,axiosParam.params)
    .then(res => {
        successCallback(res);
    }).catch(err => {
        if(failCallback != null) {
            failCallback(err);
        } else {
            console.log(err);
        }
    })
}
export function fn_get_axios(axiosParam, successCallback, failCallback) {
    axiosInstance.get(axiosParam.url, {params : axiosParam.params})
    .then(res => {
        successCallback(res);
    }).catch(err => {
        if(failCallback != null) {
            failCallback(err);
        } else {
            console.log(err);
        }
    })
}

export function fn_delete_axios(axiosParam, successCallback, failCallback) {
    axiosInstance.delete(axiosParam.url, {params : axiosParam.params})
    .then(res => {
        successCallback(res);
    }).catch(err => {
        if(failCallback != null) {
            failCallback(err);
        } else {
            console.log(err);
        }
    })
}
export function fn_post_fileUpload_axios(fileList, successCallback, failCallback) {
    const fd = new FormData();

    
    for (let i = 0; i < fileList.length; i++) {  
        fd.append("file", fileList[i]);  
    }

    axios.post(`${API.BACKEND}/${API.VERSION}/file/uploadFile`, fd, {
      headers: {
        "Content-Type": `multipart/form-data; `,
      }
    })
    .then(res => {
        successCallback(res);
    }).catch(err => {
        if(failCallback != null) {
            failCallback(err);
        } else {
            console.log(err);
        }
    })
}
export function fn_get_fileDownload_axios(atchFileId, successCallback, failCallback) {
    axios.get(`${API.BACKEND}/${API.VERSION}/file/downloadFile?atchFileId=${atchFileId}`, {
        headers: {
            'Content-Type': `multipart/form-data;`,
            'responseType': 'arraybuffer'
        },
        responseType : 'blob'
    })
    .then(res => {
        // 다운로드(서버에서 전달 받은 데이터) 받은 바이너리 데이터를 blob으로 변환합니다.
        const blob = new Blob([res.data]);

        // blob을 사용해 객체 URL을 생성합니다.
        const fileObjectUrl = window.URL.createObjectURL(blob);
        
        // blob 객체 URL을 설정할 링크를 만듭니다.
        const link = document.createElement("a");
        link.href = fileObjectUrl;
        link.style.display = "none";
        
        // 다운로드 파일 이름을 지정 할 수 있습니다.
        // 일반적으로 서버에서 전달해준 파일 이름은 응답 Header의 Content-Disposition에 설정됩니다.
        link.download = extractDownloadFilename(res);
        
        // 링크를 body에 추가하고 강제로 click 이벤트를 발생시켜 파일 다운로드를 실행시킵니다.
        document.body.appendChild(link);
        link.click();
        link.remove();
        
        // 다운로드가 끝난 리소스(객체 URL)를 해제합니다.
        window.URL.revokeObjectURL(fileObjectUrl);
    }).catch(err => {
        if(failCallback != null) {
            failCallback(err);
        } else {
            console.log(err);
        }
    })

    // 다운로드 파일 이름을 추출하는 함수
    const extractDownloadFilename = (res) => {
        const disposition = res.headers['content-disposition'];
        const fileName = decodeURI(
            disposition
            .match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1]
            .replace(/['"]/g, "")
            );
        return fileName;
        };
}


//cookie 관련 함수
const cookies = new Cookies();

// export const setCookie = (name: string, value: string, options?: any) => {
//  	return cookies.set(name, value, {...options}); 
// }

export const getCookie = (name: string) => {
    // let matches = document.cookie.match(new RegExp(
    //     "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    //   ));
    //   return matches ? matches[1] : undefined;
    return cookies.get(name);
}

export const removeCookie = (name: string) => {
    return cookies.remove(name,{path:"/", domain:'.' + API.DOMAIN});
}


    

export const logout = () => {
    removeCookie("Access_Token");
    removeCookie("Refresh_Token");
    removeCookie("userProfileFileUrl");
    removeCookie("userId");
    window.location.href = `/`;
}

// 생년월일이 'YYYY-MM-DD' 형식으로 주어졌을 때, 19세 미만인지 체크하는 함수
export const isUnderNineteen = (birthdateString) => {
    // 입력된 생년월일을 날짜 객체로 변환
    const birthDate = new Date(birthdateString);

    // 현재 날짜 구하기
    const currentDate = new Date();
    
    // 19년 전의 날짜 구하기
    const nineteenYearsAgo = new Date(currentDate.getFullYear() - 19, currentDate.getMonth(), currentDate.getDate());

    // 생년월일이 19년 전보다 이후인지 체크
    return birthDate > nineteenYearsAgo;
};


export const saveCookie = (name:string, value:string,expiresDay) => {
    const expiresDate = new Date();
    expiresDate.setDate(expiresDate.getDate() + expiresDay);
    return cookies.set(name, value,{path:"/",expires:expiresDate});
}

export function setingIsLogin(isvalue:boolean) {
    if(isvalue) {
        return saveCookie("isAutoLogin",'True',30);
    } else {
        return removeCookie("isAutoLogin");
    }
}

export function setingEmail(isvalue:boolean, userEmail:string) {
    if(isvalue) {
        return saveCookie("userEmail",userEmail,60);
    } else {
        return removeCookie("userEmail");
    }
}

export function getAccessToken() {
    return getCookie("Access_Token");
}
export function getRefreshToken(){
    return getCookie("Refresh_Token");
}
export function getUserProfileFileUrl(){
    return getCookie("userProfileFileUrl");
}
export function getCookieUserId(){
    return getCookie("userId");
}
export function getCookieUserEmail(){
    return getCookie("userEmail");
}
export function getIsAutoLogin() {
    return getCookie("isAutoLogin");
}

export function getWeek(paramDate) {
    switch(new Date( moment(paramDate).format('YYYY-MM-DD')).getDay()) {
        case 0: return "일"
        case 1: return "월"
        case 2: return "화"
        case 3: return "수"
        case 4: return "목"
        case 5: return "금"
        case 6: return "토"
        default:
            return "";

    }
}

// 날짜 비교(오늘 일시와 비교)
export function compareToday(targetDate){
    const endDate = new Date(targetDate);
    const today = new Date();

    return endDate < today;
  };