import React from 'react';
import {useState, useEffect} from 'react';
import Layout from '../layout/Layout.tsx';
import {getAccessToken,fn_axios,logout,getCookieUserId} from "../common/common.tsx";
import {API} from '../config/config.tsx';
import  {initializeKeenSlider} from "../assets/js/ui.js";
import moment from "moment";
import Popup from '../common/Popup.tsx';

function Home() {
  const [user, setUser] = useState("");
  const [actvieIndex, setActvieIndex] = useState(0);
  const [userGameItems, setUserGameItems] = useState([]);
  const [reqsCompletCmpt, setReqsCompletCmpt] = useState([]);
  const [reqsCmpt, setReqsCmpt] = useState([]);
  const [gameItemList, setGameItemList] = useState([]);
  const [mainBnnrList, setMainBnnrList] = useState([]);
  const [cmptBnnrList, setCmptBnnrList] = useState([]);
  const [eventBnnrList, setEventBnnrList] = useState([]);
  const [cmptItrstList, setCmptItrstList] = useState([]);
  const [boardList, setBoardList] = useState([]);
  const [isFirst, setIsFirst] = useState(true);

  const getBoardPaging = (num) => {
    const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/board/getBoardPaging`,
    params : {      pageNum : 1
                  , searchKeyword : null
                  , pageCnt : 3
                  , boardDivsCd : 'BOARD_DIVS_CD_01'
              }
    }
    fn_axios(param,callbackGetBoardPaging,null);
  }
  const callbackGetBoardPaging = (res) => {
    setBoardList(res.data.object);
  }
  const handleGameItme = (index) => {
    setActvieIndex(index);
  } 
  const movePoint = () => {
    window.location.href = "/user/point";
  }

    // 경기 종목 조회.
  const getGameItem = () => {
    const param = {
    method: 'GET',
    url: `${API.BACKEND}/v1/gameItem/getGameItem`,
    params: null,
    };
    fn_axios(param, callBackGetGameItem, null);
  };

    const callBackGetGameItem = (res) => {
        setGameItemList(res.data.object);
    }

  const getBnnrPaging = () => {
    const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/bnnr/getAllBnnrPaging`,
    params : {pageNum : 1
              }
    }
    fn_axios(param,callbackGetBnnrPaging,null);
  }

  const callbackGetBnnrPaging = (res) => {
    setMainBnnrList(res.data.map.mainBnnrList);
    setCmptBnnrList(res.data.map.cmptBnnrList);
    setEventBnnrList(res.data.map.eventBnnrList);
  }
  const getCmptItrst = () => {
    const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/cmpt/getCmptItrst`,
    params : {pageNum : 1
            , pageCnt : 3
            , userId : getCookieUserId()
              }
    }
    fn_axios(param,callbackGetCmptItrst,null);
  }

  const callbackGetCmptItrst = (res) => {
    setCmptItrstList(res.data.object);
  }

  const getCompletCmptPasing = () => {
    const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/cmpt/getCmptPaging`,
    params : {pageNum : 1
                  , pageCnt : 3
                  , enterUserId : getCookieUserId()
                  , reqRsltCd : 'REQ_RSLT_CD_02'
                  
              }
  }
  fn_axios(param,callbackGetCmptPasing,null);
  }

  const getUserItrstItem = () => {
		const param = {
			method : 'GET',
			url : `${API.BACKEND}/v1/user/getUserItrstItem`,
			params : {userId:null}
		}
		fn_axios(param,callbackGetUserItrstItem,null);
	};

  const callbackGetUserItrstItem = (res) => {
      setUserGameItems(res.data.object);
      getGameItem();
  }

  const getUser = () => {
    const param = {
      method : 'GET',
      url : `${API.BACKEND}/v1/user/getMainUser`,
      params : {userId:null
      }
    }
    fn_axios(param,callbackGetUser,null);
  };
  const getReqsCmptPasing = () => {
    const param = {
    method : 'GET',
    url : `${API.BACKEND}/${API.VERSION}/cmpt/getCmptPaging`,
    params : {pageNum : 1
                  , pageCnt : 3
                  , reqUserId : getCookieUserId()
                  , reqRsltCd : 'REQ_RSLT_CD_01'
                  
              }
    }
    fn_axios(param,callbackGetReqsCmptPasing,null);
  }

  const checkUserGameItem = (gameCd) => {
    if(userGameItems == null ) return false;

    for (let item of userGameItems) {
      if (item.gameKindCd == gameCd) {
        return true;
      }
    }
    return false;
  }

  const callbackGetReqsCmptPasing = (res) => {
      setReqsCmpt(res.data.object);
  }

  const callbackGetUser = (res) => {
    setUser(res.data.object);
  }

  const callbackGetCmptPasing = (res) => {
      setReqsCompletCmpt(res.data.object);
  }
  const eventStyle = {
    wordBreak: 'break-all', // 또는 'break-word'
  };

  const drawMainBnnrList = (list) => {
    let itemElements = [];
  
    for (let i = 0; i < list.length; i += 2) {
      itemElements.push(
        <div className={`keen-slider__slide number-slide${i === 0 ? "1" : (i / 2) + 1}`} key={i}>
          <img src={list[i].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i].bnnrLink}&bnnrLId=${list[i].bnnrLId}`} style={{aspectRatio: "1 / 1.4"}}/>
          { (i + 1) < list.length &&
            <img src={list[i + 1].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i + 1].bnnrLink}&bnnrLId=${list[i + 1].bnnrLId}`} style={{aspectRatio: "1 / 1.4"}}/>
          }
        </div>
      );
    }
    return itemElements;
  }
  const drawPcSubBnnrList = (list) => {
    let itemElements = [];
  
    for (let i = 0; i < list.length; i += 4) {
      itemElements.push(
        <div className={`silde01 keen-slider__slide number-slide${i === 0 ? "1" : (i / 4) + 1}`}>
          <div>
            <img src={list[i].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i].bnnrLink}&bnnrLId=${list[i].bnnrLId}`} />
            <p>{list[i].bnnr_title}</p>
          </div>
          { (i + 1) < list.length &&
          <div>
            <img src={list[i + 1].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i + 1].bnnrLink}&bnnrLId=${list[i + 1].bnnrLId}`} />
            <p>{list[i + 1].bnnr_title}</p>
          </div>
          }
          { (i + 2) < list.length &&
          <div>
            <img src={list[i + 2].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i + 2].bnnrLink}&bnnrLId=${list[i + 2].bnnrLId}`} />
            <p>{list[i + 2].bnnr_title}</p>
          </div>
          }
          { (i + 3) < list.length &&
          <div>
            <img src={list[i + 3].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i + 3].bnnrLink}&bnnrLId=${list[i + 3].bnnrLId}`} />
            <p>{list[i + 3].bnnr_title}</p>
          </div>
          }
        </div>
      );
    }
  
    return itemElements;
  }
  const drawMobileSubBnnrList = (list) => {
    let itemElements = [];
    let i = 0;
      itemElements.push(
        <div className="silde01">
          <div className="keen-slider__slide number-slide1">
              <img src={list[i].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i].bnnrLink}&bnnrLId=${list[i].bnnrLId}`} />
              <p>{list[i].bnnr_title}</p>
          </div>
          { (i + 1) < list.length &&
          <div className="keen-slider__slide number-slide2">
            <img src={list[i + 1].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i + 1].bnnrLink}&bnnrLId=${list[i + 1].bnnrLId}`} />
            <p>{list[i + 1].bnnr_title}</p>
          </div>
          }
          { (i + 2) < list.length &&
          <div className="keen-slider__slide number-slide3">
            <img src={list[i + 2].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i + 2].bnnrLink}&bnnrLId=${list[i + 2].bnnrLId}`} />
            <p>{list[i + 2].bnnr_title}</p>
          </div>
          }
          { (i + 3) < list.length &&
          <div className="keen-slider__slide number-slide4">
            <img src={list[i + 3].bnnrFileUrl} alt="" onClick={() => window.location.href = `${list[i + 3].bnnrLink}&bnnrLId=${list[i + 3].bnnrLId}`} />
            <p>{list[i + 3].bnnr_title}</p>
          </div>
          }
        </div>
      );
  
    return itemElements;
  }

  const openPopup = () => {
    // return Popup(null,"","","https://s3-wooriground-bucket01.s3.ap-northeast-2.amazonaws.com/wooriground-fileserver/20240506/%EC%9A%B0%EB%A6%AC%EA%B7%B8%EB%9D%BC%EC%9A%B4%EB%93%9C+%EB%A9%94%EC%9D%B8+%ED%8C%9D%EC%97%85_01.png",0);
    return Popup({width:"55%"},"","","https://s3-wooriground-bucket01.s3.ap-northeast-2.amazonaws.com/wooriground-fileserver/20241022/544.png",0,"https://sponet.co.kr/suwon2/#!/main/");
  }

  useEffect(() => {
    if(getAccessToken() == null ) {
      getGameItem();
    } else {
      getUser();
      getUserItrstItem();
      getCompletCmptPasing();
      getReqsCmptPasing();
      getCmptItrst();
      
    }
    getBoardPaging(1);
    getBnnrPaging();
    }, [])

  useEffect(() => {
    if(mainBnnrList != null && mainBnnrList.length > 0) {
      initializeKeenSlider();
    }
    }, [mainBnnrList])
    return (
      <Layout>
      <div className="contents">
          {/* <!-- pc visual --> */}
          <section className="visual pc">
            <div id="my-keen-slider1" className="keen-slider">
              <div className="img" >
                {mainBnnrList == null ? "" : drawMainBnnrList(mainBnnrList)}
            </div>
            </div>
          </section>
          {/* <!-- //pc visual --> */}
          {/* <!-- mobile visual --> */}
          <section className="visual mo" style={{width:"100%",paddingLeft:"0px",paddingRight:"0px"}}>
            <div id="my-keen-slider2" className="keen-slider" style={{width:"100%"}}>
              <div className="img">
                {mainBnnrList == null ? null : mainBnnrList.map((item, index) => (
                  <div className={`keen-slider__slide number-slide${index+1}`} >
                    <img key={index} src={item.bnnrFileUrl} alt="메인배너"onClick={() => window.location.href = `${item.bnnrLink}&bnnrLId=${item.bnnrLId}`} style={{width: "100%",height: "100%",objectFit: "cover",aspectRatio: "1 / 1.4"}}/>
                  </div>
                ))}
              </div>
            </div>
          </section>
          {/* <!-- //mobile visual --> */}
          {getAccessToken() != null ? 
          // 로그인했을때
          <section className="my_schedule">
            <div className="my_info">
              <div className="data">
                <b>{user == null ? null : user.userNm}</b> <span>(<a href="#" onClick={movePoint}><span>{user == null ? null : user.point}</span> point</a>)</span> 님께서는 <br/> <span>{user == null ? null : user.enterGameKindNum}</span>종목 <span>{user == null ? null : user.enterCmptGameNum}</span>경기에 참가하셨습니다. 
              </div>
              <div className="choice">
                <p className="tx_tit02">원하는 대회 및 날짜 선택 </p>
                <div className="tab">
                    <div className="tab_title">
                    {userGameItems.map(( userGameItem, index) => (
                        <a style={{cursor: "pointer"}} key={index} onClick={() => handleGameItme(index)}  className={index == actvieIndex ? "active" : ""}>{userGameItem.gameKindNm}</a>
                    ))}
                    </div>
                    {userGameItems.map(( userGameItem, index) => (
                        <div key={index} className={index == actvieIndex ? "contest active" : "contest"}>
                            <div ><span>{userGameItem.m3Nm}월대회 : </span><span style={{cursor: "pointer"}} onClick={() =>  window.location.href = `/cmpt/cmptList?gameKindCd=${userGameItem.gameKindCd}&searchMonth=${userGameItem.m3Nm}`}>{userGameItem.m3Count}</span></div>
                            <div ><span>{userGameItem.m2Nm}월 : </span><span style={{cursor: "pointer"}} onClick={() =>  window.location.href = `/cmpt/cmptList?gameKindCd=${userGameItem.gameKindCd}&searchMonth=${userGameItem.m2Nm}`}>{userGameItem.m2Count}</span></div>
                            <div ><span>{userGameItem.m1Nm}월 : </span><span style={{cursor: "pointer"}} onClick={() =>  window.location.href = `/cmpt/cmptList?gameKindCd=${userGameItem.gameKindCd}&searchMonth=${userGameItem.m1Nm}`}>{userGameItem.m1Count}</span></div>
                        </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="my_contest">
              <div className="list">
                <p>
                    내가 참가신청 완료한 대회
                    <a style={{cursor: "pointer"}} className="btn_r_black" onClick={() => window.location.href = `/user/userReqCmptList`}>참가대회</a>
                </p>
                <ul>
                {reqsCompletCmpt == null || reqsCompletCmpt.length == 0 ? 
                <li>아직 신청한 대회가 없습니다.</li>
                : reqsCompletCmpt.map((item, index) => (
                    <li style={{cursor: "pointer"}} onClick={() => window.location.href = `/cmpt/cmptDetail?cmptLId=${item.cmptLId}`}>
                        <b >{item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(item.cmptReqDday) >= 0 ? "D-" + Math.abs(item.cmptReqDday) : "D+" + Math.abs(item.cmptReqDday) ) : 
                                                                    (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(item.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
                                                                    (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감"))} </b>
                        {item.cmptLNm}</li>
                ))}
                </ul>
              </div>
              <div className="list">
                <p>
                    내가 관심있는 대회
                    <a  style={{cursor: "pointer"}} className="btn_r_black" onClick={() => window.location.href = `/user/userCmptItrstList`}>관심대회</a>
                </p>
                <ul>
                {cmptItrstList == null || cmptItrstList.length == 0 ? 
                <li>관심 있는 대회가 없습니다.</li>
                : cmptItrstList.map((item, index) => (
                    <li style={{cursor: "pointer"}} onClick={() => window.location.href = `/cmpt/cmptDetail?cmptLId=${item.cmptLId}`}>
                    <b >{item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(item.cmptReqDday) >= 0 ? "D-" + Math.abs(item.cmptReqDday) : "D+" + Math.abs(item.cmptReqDday) ) : 
                                                                (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(item.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
                                                                (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감"))} </b>
                    {item.cmptLNm}</li>
                ))}
                </ul>
              </div>
              <div className="list">
                <p>
                    내가 참가신청 중인 대회
                </p>
                <ul>
                {reqsCmpt == null || reqsCmpt.length == 0 ? 
                    <li>신청중인 대회가 없습니다.</li>
                    : reqsCmpt.map((item, index) => (
                        <li style={{cursor: "pointer"}} onClick={() => window.location.href = `/cmpt/cmptDetail?cmptLId=${item.cmptLId}`}>
                        <b >{item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_01" ? (parseInt(item.cmptReqDday) >= 0 ? "D-" + Math.abs(item.cmptReqDday) : "D+" + Math.abs(item.cmptReqDday) ) : 
                                                                    (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_02" ? (new Date(item.cmptReqEndDtm) < new Date() ? "접수마감" : "접수중") : 
                                                                    (item.cmptStateDvisCd == "CMPT_STATE_DIVS_CD_03" ? "접수마감" : "대회마감"))} </b>
                        {item.cmptLNm}</li>
                    ))}
                </ul>
              </div>
            </div>
          </section>
          :
          null}
          <section className={getAccessToken() != null ? "schedule type1" : "schedule"}>
              <div className={getAccessToken() != null ? "" : "sec01"}>
                <div className="list">
                  <p className="tx_tit01">종목별 대회일정</p>
                  <div className="event" style={{wordBreak : "break-all"}} >
                  {gameItemList == null || gameItemList.length == 0 ? null 
                    : gameItemList.map((item, index) => (
                      checkUserGameItem(item.gameKindCd) ?
                        <a href="#" onClick={() => window.location.href = `/cmpt/cmptList?gameKindCd=${item.gameKindCd}&gameKindNm=${item.gameKindNm}`}><b>{item.gameKindNm}&nbsp;</b></a>
                        :
                        <a href="#" onClick={() => window.location.href = `/cmpt/cmptList?gameKindCd=${item.gameKindCd}&gameKindNm=${item.gameKindNm}`}>{item.gameKindNm} &nbsp;</a>
                    ))}
                    <a className="btn_plus" style={{cursor: "pointer"}} ></a>
                  </div>
                </div>
                {getAccessToken() != null ? null : 
                <div className="login">
                  <a href="/user/login" className="btn_login">로그인</a>
                  <p>내가 찾던 대회를 원하는 날에, <br className="pc"/> 우리그라운드 <a href="/user/joinAgree" className="btn_membership">회원가입</a></p>
                </div>
                }
              </div>
            </section>

          {/* <!-- search list --> */}
          <section className="search slide_list">
            <div className="title_box">
              <h1>당신이 찾고있는 <br className="mo"/> 바로 그 대회</h1>
              <a className="btn_r_black" href="#" onClick={() => window.location.href = `/cmpt/cmptList`}>추천대회</a>
            </div>
            {/* <!-- pc ver slide1 --> */}
            <div className="pc">
              <div id="my-keen-slider3" className="keen-slider slide_box"> 
              {(cmptBnnrList == null || cmptBnnrList.length == 0 ) ? null : drawPcSubBnnrList(cmptBnnrList)}
              </div>
            </div>
            {/* <!-- //pc ver slide1 --> */}
            {/* <!-- mo ver slide1 --> */}
            <div className="mo">
              <div id="my-keen-slider4" className="keen-slider slide_box"> 
                {(cmptBnnrList == null || cmptBnnrList.length == 0 ) ? null : drawMobileSubBnnrList(cmptBnnrList)}
              </div>
            </div>
            {/* <!-- //mo ver slide1 --> */}
          </section>
          {/* <!-- //search list --> */}

          {/* <!-- recommend list --> */}
          <section className="recommend slide_list">
            <div className="title_box">
              <h1 className="ico_star">우그우그 추천</h1>
              <a className="btn_r_black"  href="#" onClick={() => window.location.href = `/event/eventList`}>이벤트</a>
            </div>
            {/* <!-- pc ver slide2 --> */}
            <div className="pc">
              <div id="my-keen-slider5" className="keen-slider slide_box pc"> 
                 {(eventBnnrList == null || eventBnnrList.length == 0 ) ? null : drawPcSubBnnrList(eventBnnrList)}
              </div>
            </div>
            {/* <!-- //pc ver slide2 --> */}
            {/* <!-- mo ver slide2 --> */}
            <div className="mo">
              <div id="my-keen-slider6" className="keen-slider slide_box"> 
                {(eventBnnrList == null || eventBnnrList.length == 0 ) ? null : drawMobileSubBnnrList(eventBnnrList)}
              </div>
            </div>
            {/* <!-- //mo ver slide2 --> */}
          </section>
          {/* <!-- //recommend list --> */}

          {/* <!-- notice --> */}
          <section className="notice">
            <div className="list">
              <div className="title">
                알려드립니다.
                <a className="btn_r_black mo" href="#">공지사항</a>
              </div>
              <ul>
              {boardList == null ? null : boardList.map((item, index) => (
                <li>
                  <p><a href="#" onClick={() => window.location.href = `/board/boardList?boardId=${item.boardId}`}>{item.boardTitle}</a></p>
                  <span>{moment(item.regrDtm).format('YYYY-MM-DD')}</span>
                </li>
              ))}
              </ul>
            </div>
            <div className="woog_me">
              <p className="tx_type01 pc"><b>당신이 원하는 대회를 원하는 장소에서</b> <br/> 당신의 대회를 알리고 싶다면, <br/> <b>WOORI GROUND!</b> WOOG ME! CONTACT ME!</p>
              <div className="woog_open">
                <a href="#"><b>대회</b>개설하기</a>
                <p>대회개설은 사업자등록회원 전용서비스이며 회원님의 기본정보와 연동하여 진행됩니다.</p>
              </div>
            </div>
          </section>
        </div>
        {/* {isFirst ? openPopup() : null} */}
      </Layout>
    );
  }
  
  export default Home;