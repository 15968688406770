import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import AdminLyout from '../../layout/admin/AdminLayout.tsx';

import { fn_axios, getCookieUserId } from '../../common/common.tsx';
import { API } from '../../config/config.tsx';

function CodeManage() {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);

    const [codeMngList, setCodeMngList] = useState([]);
    const [codeDetailInfo, setCodeDetailInfo] = useState([]);
    const [selectCode, setSelectCode] = useState(null);

    //search
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedOrdColumn, setSelectedOrdColumn] = useState('');
    const handleSearchKeyword = (e) => {
        setSearchKeyword(e.target.value);
    };
    const onClickSearch = () => {
        getCode();
    }
    // codeL form
    const [codeLCd, setCodeLCd] = useState("");
    const [codeMCd, setCodeMCd] = useState("");
    const [codeLNm, setCodeLNm] = useState("");
    const [codeLDesc, setCodeLDesc] = useState("");

    const getCode = () => {
        const param = {
            method: 'GET',
            url: `${API.BACKEND}/${API.VERSION}/mng/getCodeM`,
            params: {
                searchKeyword: searchKeyword
            }
        };
        fn_axios(param, callBackGetCode, null);
    }
    const callBackGetCode = (res) => {
        console.log(res);
        setCodeMngList(res.data.object);
    }

    const getCodeDetail = (codeMCd) => {
        setCodeDetailInfo([]);
        const param = {
            method: 'GET',
            url: `${API.BACKEND}/${API.VERSION}/mng/getCodeDetail`,
            params: {
                selectCode: codeMCd
            }
        };
        fn_axios(param, callBackGetCodeDetail, null);
    }
    const callBackGetCodeDetail = (res) => {
        console.log(res);
        setCodeDetailInfo(res.data.object);
        if (res.data.object.length > 0) {
            setCodeMCd(res.data.object[0].codeMCd);
            console.log(setCodeMCd);
        }
    }

    const [checkMap, setCheckMap] = useState({
        "codeLCd": true,
        "codeLNm": true,
        "codeLDesc": true
    });

    const handleCodeLCd = (index, e) => {
        console.log(e.target.value);
        const updateList = [...codeDetailInfo];
        updateList[index].codeLcd = e.target.value;
        setCodeDetailInfo(updateList);
    }
    const handleCodeMCd = (index, e) => {
        const updateList = [...codeDetailInfo];
        updateList[index].codeMCd = e.target.value;
        setCodeDetailInfo(updateList);
    }
    const handleCodeLNm = (index, e) => {
        console.log(e.target.value);
        const updateList = [...codeDetailInfo];
        updateList[index].codeLNm = e.target.value;
        setCodeDetailInfo(updateList);
    }
    const handleCodeLDesc = (index, e) => {
        console.log(e.target.value);
        const updateList = [...codeDetailInfo];
        updateList[index].codeLDesc = e.target.value;
        setCodeDetailInfo(updateList);
    }

    const updateCode = () => {
        console.log(codeDetailInfo);
        return;
        const param = {
            method: 'POST',
            url: `${API.BACKEND}/${API.VERSION}/mng/getUpdateCodeL`,
            params: {
                codeLCd: codeLCd,
                codeMCd: codeMCd, // codeMCd
                codeLNm: codeLNm,
                codeLDesc: codeLDesc,
                updateUserId: getCookieUserId()
            }
        }
        fn_axios(param, clickUpdate, null);
    }

    // 코드 내역 수정 request
    const clickUpdate = () => {
        if (!codeLCd) {
            alert("코드내역을 입력해주세요.");
            return;
        }
        if (!codeLNm) {
            alert("코드명을 입력해주세요.");
            return;
        }
        if (!codeLDesc) {
            alert("코드내역설명을 입력해주세요.");
        }
    }

    useEffect(() => {
        getCode();
        updateCode();
    }, []);

    return (
        <AdminLyout>
            <div>
                <h1 className="sub_title2">코드 관리</h1>
                <div className="search_box odr1">
                    <input type="text" style={{ minWidth: "90px" }} name="keyword" value={searchKeyword} onChange={handleSearchKeyword} placeholder="키워드" />
                    <button onClick={onClickSearch}>검색</button>
                </div>
                <section className="admin_code_list">
                    <div className="search-bar">
                    </div>
                    <div className="code-management-container">
                        <div className="admin-code-mng">
                            <table style={{ borderCollapse: "collapse", width: "100%", tableLayout: "auto" }}>
                                <thead>
                                    <tr>
                                        <td>코드</td>
                                        <td>코드 명</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {codeMngList.map((item, index) => (
                                        <tr key={index} onClick={() => getCodeDetail(item.codeMCd)}>
                                            {/*<td style={{ whiteSpace: "nowrap", paddingLeft: "5px" }}>{item.codeMCd}</td>*/}
                                            <td>{item.codeMCd}</td>
                                            <td>{item.codeMNm}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="admin_code_details">
                        <table style={{ borderCollapse: "collapse", width: "100%", tableLayout: "auto" }}>
                            <thead>
                                <tr>
                                    <td>코드내역</td>
                                    <td>코드ID</td>
                                    <td>코드내역명</td>
                                    <td>코드내역설명</td>
                                    <td>등록일시</td>
                                    <td>등록자</td>
                                    <td>수정일시</td>
                                    <td>수정자</td>
                                </tr>
                            </thead>
                            <tbody>
                                {codeDetailInfo.map((item, index) => (
                                    <tr key={index}>
                                        <td><input defaultValue={item.codeLCd} onChange={(event) => handleCodeLCd(index, event)} /></td>
                                        <td><input type="text" value={item.codeMCd} /></td>
                                        <td><input defaultValue={item.codeLNm} onChange={(event) => handleCodeLNm(index, event)} /></td>
                                        <td><input defaultValue={item.codeLDesc} onChange={(event) => handleCodeLDesc(index, event)} /></td>
                                        <td><input type="text" value={item.regrUserId} /></td>
                                        <td><input type="text" value={item.regrDtm} /></td>
                                        <td><input type="text" value={item.updateUserId} /></td>
                                        <td><input type="text" value={item.updateDtm} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </section>
                <div className="t_ct">
                    <button className="btn_type1 complete">저장</button>
                    <button className="btn_type1 complete" onClick={updateCode}>코드내역수정</button>
                </div>
            </div >
        </AdminLyout >
    )
}

export default CodeManage;