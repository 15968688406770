import React from "react";
import {useEffect,} from 'react';

import Layout from "../../layout/Layout.tsx";
import Slider from "../../layout/Slider.tsx";


function NotFoundPage() {

useEffect(() => {
  }, []); 

return (
  <Layout>
    <div className="contents contest">
      <section>
        <div style={{ textAlign: "center", padding: "50px" }}>
          <h1>404 - Page Not Found</h1>
          <br/>
          <h1>없는 페이지 입니다.</h1>
        </div>
      </section>
      <Slider></Slider> 
    </div>
  </Layout>
  );
}

export default NotFoundPage;
